<template>
    <div class="nav wrap" :class="{'has-topbar': hasTopbar}">
        <div class="color-bg" :class="isHover ? 'active' : ''"></div>
        <!-- 有字的部分 -->
        <div class="top-mid container" @mouseenter="onMouseEnterHand" @mouseleave="onMouseLeaveHand">
            <!-- logo -->
            <a v-if="$i18n.locale === 'en'" class="nav-logo en" href="./index.html">
                <img class="imgtop" src="@/assets/images/logo-en.svg"/>
            </a>
            <a v-else class="nav-logo cn" href="./index.html">
                <img class="imgtop" src="@/assets/images/logo-cn.svg"/>
            </a>
            <!-- 左侧的导航栏 -->
            <div class="nav-box">
                <template v-for="(item, i) in linkList">
                    <template v-if="item.type === 'isLogin'">
                        <!-- 需要登录态才能跳转的小模板 -->
                        <a @click="toLinkByLogin(item.link)" :key="'link' + i" :target="item.target">{{item.name}}</a>
                    </template>
                    <a v-else :target="item.target" :href="item.link" :key="'link' + i">
                        {{item.name}}
                    </a>
                </template>
            </div>
            <!-- 登录状态栏 -->
            <div class="nav-login">
                <div @click="toLinkByLogin('/changeinfo.html')" class="btn-changeinfo nav-login-btn bg-color"><i class="iconfont right">&#xe6b7;</i>{{$t('common.head.btnChangeinfo')}}</div>

                <template v-if="isLogin">
                    <div class="btn-message nav-login-btn bg-color" @click="btnNote">
                        <i class="iconfont">&#xe63a;</i>
                        <span class="span-count" v-if="messageCount">{{messageCount}}</span>
                    </div>
                    <div class="btn-loginout nav-login-btn">
                        <span>{{userName || 'xxx'}}<i class="iconfont left">&#xe6ed;</i></span>
                        <div class="btn-child" @click="btnLoginout">
                            {{$t('common.head.btnLoginout')}}
                        </div>
                    </div>
                </template>
                
                <template v-else>
                    <div class="btn-register nav-login-btn" @click="btnRegister">{{$t('common.head.btnRegister')}}</div>
                    <div class="btn-login nav-login-btn" @click="btnLogin">{{$t('common.head.btnLogin')}}</div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {login, register, loginout, getDevOperates} from '@/api';
// import {clearCookie} from '@/api/cookie-config';
import {getCookieValueByName} from '@/utils/tool';
import {getT} from '@/model/i18n/apiMessage';

export default {
    props: {
        // css
        hasTopbar: {
            default() {
                return true;
            }
        },
        // 数据
        updateMessageStatus: {
            type: Number,
        },
    },
    watch: {
        updateMessageStatus() {
            this.getMessageList();
        },
    },
    data() {
        return {
            isHover: false,
            userName: '',
            isLogin: false,
            messageCount: 0,
            linkList: [],
        };
    },

    created() {
        this.initLoginStatus();
    },

    methods: {
        onMouseEnterHand() {
            this.isHover = true;
            console.log('enter');
        },
        onMouseLeaveHand() {
            this.isHover = false;
            console.log('leave');
        },

        btnNote() {
            location.href = '/note1.html';
        },

        btnRegister() {
            // this.isLogin = true;
            return register();
        },
        btnLogin() {
            // this.isLogin = true;
            return login();
        },
        btnLoginout() {
            loginout();
            if (process.env.NODE_ENV === 'development') {
                location.reload();
            }
        },

        getMessageList(userId = getCookieValueByName('userId')) {
            if (!userId) {
                return this.messageCount = 0;
            }
            return getDevOperates(userId)
                    .then(res => {
                        if (res.code === 200) {
                            sessionStorage.setItem('user-401', '');
                            this.messageCount = res.data.filter(item => {
                                return item.isNew === '1';
                            }).length;

                            this.$emit('messageList', [...res.data]);
                        }
                        else if (res.code === 401) {
                            console.log('userId', userId)
                            // 防止登录状态过期下，退出登录的锁死
                            // if (sessionStorage.getItem('user-401') !== userId) {
                            //     sessionStorage.setItem('user-401', userId);
                            //     this.$myConfirm(getT('401'))
                            //         .then(type => {
                            //             // enter close
                            //             console.log('getDevOperates', type);
                            //             this.clearLoginStatus();

                            //         });
                            // }
                            // else {
                            //     clearCookie();
                            //     this.initLoginStatus();
                            // }
                            
                        }
                    });
        },

        initLoginStatus() {
            const userId = getCookieValueByName('userId');
            const nickname = getCookieValueByName('nickname');
            

            if (userId) {
                this.isLogin = true;
                this.userName = decodeURI(decodeURIComponent(nickname));
                this.getMessageList();

            }
            else {
                this.isLogin = false;
            }

            



        },

        clearLoginStatus() {
            loginout();
            // this.initLoginStatus();
        },

        toLinkByLogin(link) {
            if (this.isLogin) {
                return location.href = link;
            }
            this.$myConfirm(getT('unLogin'));
        },

        updateI18n() {
            // 放在这里的会自动更新
            this.linkList = [
                {
                    name: this.$t('common.head.linkHome'),
                    link: '/index.html',
                    target: '',
                },
                {
                    name: this.$t('common.head.linkDoc'),
                    link: '/document.html',
                    target: '_target',
                },
                {
                    name: this.$t('common.head.linkDownload'),
                    link: '/download.html',
                    target: '_target',
                    type: 'isLogin'
                },
                {
                    name: this.$t('common.head.linkIoT'),
                    link: 'https://iot.mi.com/',
                    target: '_target',
                },
                {
                    name: this.$t('common.head.linkToBMI'),
                    link: this.$i18n.locale === 'cn' ? 'https://b.mi.com/' : 'https://global.b.mi.com/',
                    target: '_target',
                },
            ];
        },
    },
};
</script>

<style lang="less">
.nav {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 999;

    &.has-topbar {
        position: absolute;
        top: 40px;
    }

    .color-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(53, 53, 63, .5);
        opacity: 0;
        transition: opacity .7s;

        &:hover {
            opacity: 1;
        }
        &.active {
            opacity: 1;
        }
    }


    
    a {
        color: #fff;
    }

    .container {
        @ncHeight: 65px;
        position: relative;
        display: flex;
        align-items: center;
        height: @ncHeight;

        .nav-logo {
            margin-left: 10px;
            
            img {
                display: block;
                height: 32px;
            }
        }
        .logo-box {
            display: flex;
            align-items: center;

            .logo-icon {
                display: block;
                width: 30px;
                height: 30px;
            }

            &.en {
                .imgtop-box {
                    width: 170px;
                }
            }

            .imgtop-box {
                position: relative;
                margin-left: 10px;
                width: 225px;
                height: 30px;
                overflow: hidden;

                .imgtop {
                    display: block;
                    position: absolute;
                    width: 260px;
                    height: 30px;
                    top: 0;
                    right: 0px;

                }
            }
        }

        .nav-box {
            margin-left: 82px;
            font-size: 14px;

            a {
                display: inline-block;
                position: relative;
                text-align: center;
                margin: 0 20px;
                line-height: @ncHeight;
                height: @ncHeight;
                cursor: pointer;

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: block;
                    width: 100%;
                    height: 3px;
                    background: linear-gradient(90deg,#ff7107,#fbc44f);
                    transform: scale(0);
                    transition: transform .3s;
                }

                &:hover {
                    color: #ff6b02;
                    &::after {
                        transform: scale(1);
                    }
                    
                }
            }
        }

        .nav-login {
            position: absolute;
            right: 10px;
            height: 100%;
            top: 0;
            width: 600px;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            font-size: 14px;

            .btn-message {
                .span-count {
                    position: absolute;
                    display: block;
                    top: 15px;
                    right: 14px;
                    background: #ef5050;
                    width: 16px;
                    height: 16px;
                    border-radius: 100%;
                    font-size: 12px;
                    color: #fff;
                    line-height: 16px;
                    text-align: center;

                }
            }


            .nav-login-btn {
                position: relative;
                height: @ncHeight;
                line-height: @ncHeight;
                padding: 0 20px;
                cursor: pointer;
                color: #fff;
                

                .iconfont.right {
                    margin-right: 5px;
                }

                .iconfont.left {
                    margin-left: 5px;
                }

                &:hover {
                    .btn-child {
                        display: block;
                    }


                }

                .btn-child {
                    display: none;
                    position: absolute;
                    width: 116px;
                    height: 40px;
                    top: @ncHeight;
                    right: 20px;
                    line-height: 40px;
                    text-align: center;
                    
                    
                    background: rgba(0, 0, 0, .5);

                    &:hover {
                        color: #ff6b02;
                    }
                }
                &.bg-color {
                    &:hover {
                        background: linear-gradient(90deg, #ff7107, #fbc44f);
                        color: #fff;
                    }
                }
                &.btn-register,
                &.btn-login {
                    &:hover {
                        color: #ff6b02;
                    }
                }

                &.btn-loginout {
                    .iconfont {
                        display: inline-block;
                        transition: transform .3s;
                    }
                    &:hover {
                        span {
                            color: #ff6b02;
                        }
                        .iconfont {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}
</style>
